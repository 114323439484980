























































































































import { Vue, Component } from 'vue-property-decorator'
import { TYPE } from 'vue-toastification'
import Page from '@/components/layouts/page.vue'

// import loginBackground from '../../assets/login-background.jpg'
import logo from '@/assets/logo.png'
import PasswordTextField from '@/components/knitiv/password-text-field.vue'
import { InjectAPI } from '@/utils/api'

@Component({
  components: {
    PasswordTextField,
    Page,
  },
  mixins: [InjectAPI],
})
export default class Login extends Vue {
  username = ''

  password = ''

  passwordResetModal = false

  isFormValid = false

  isLoading = false

  logo = logo

  endpoint = process.env.VUE_APP_API_URL

  engine = process.env.VUE_APP_API_ENGINE

  // loginBackground = loginBackground

  mounted() {
    localStorage.clear()
  }

  async openWorkspace(): Promise<void> {
    if (!this.isFormValid) {
      return
    }
    try {
      console.log('api.workspaceList')
      this.isLoading = true
      await this.$router.push({

        name: 'LoginWorkspace',
        query: this.$route.query,
        params: {
          username: this.username,
          password: this.password,
          mode: this.engine ?? '5',
          endpoint: this.endpoint ?? '',
        },
      })
    } catch (e) {
      this.$toast('Identifiant invalide', {
        type: TYPE.ERROR,
      })
      console.log('e', e)
    }
    this.isLoading = false
  }

  async resetPassword(): Promise<void> {
    try {
      const response = await this.$api.passForgotten({
        username: this.username,
      })
      console.log('response', response)
      this.$toast.error('Un email vous a été envoyé. Il contient votre nouveau mot de passe.\nRéinitialisation du mot de passe.')
    } catch {
      this.$toast.error('Votre identifiant est invalide\nNous vous invitons à contacter l\'administrateur.')
    }
    this.passwordResetModal = false
  }
}
