














import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({
  inheritAttrs: false,
})
export default class PasswordTextField extends Vue {
  @Prop({
    type: Boolean,
    required: false,
    default: false,
  })
  show!: boolean

  @Prop({
    type: String,
    required: true,
    default: '',
  })
  value!: string

  showText = this.show

  text = this.value

  onInput(value: string) {
    this.text = value
    this.$emit('value', this.text)
  }
}
